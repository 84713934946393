body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */

/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {
  /**
     * colors
     */

  --safety-orange: hsl(25, 100%, 50%);
  --lavender-gray: hsl(230, 19%, 81%);
  --persian-rose: hsl(328, 100%, 59%);
  --red-crayola: hsl(341, 100%, 49%);
  --eerie-black: hsl(240, 6%, 10%);
  --light-gray: hsl(0, 0%, 80%);
  --cultured-2: hsl(210, 60%, 98%);
  --platinum: hsl(0, 0%, 90%);
  --gray-web: hsl(220, 5%, 49%);
  --cultured: hsl(0, 0%, 93%);
  --black_10: hsla(0, 0%, 0%, 0.1);
  --black_5: hsla(0, 0%, 0%, 0.05);
  --white-1: hsl(0, 0%, 100%);
  --white-2: hsl(0, 14%, 98%);
  --black: hsl(0, 0%, 0%);

  /**
     * gradient color
     */

  --gradient: linear-gradient(
    to left top,
    var(--persian-rose),
    var(--safety-orange)
  );

  /**
     * typography
     */

  --ff-roboto: "Roboto", sans-serif;
  --ff-league-spartan: "League Spartan", sans-serif;

  --fs-1: 3.5rem;
  --fs-2: 3rem;
  --fs-3: 2.1rem;
  --fs-4: 1.7rem;
  --fs-5: 1.4rem;
  --fs-6: 1.3rem;

  --fw-700: 700;
  --fw-500: 500;

  /**
     * spacing
     */

  --section-padding: 60px;

  /**
     * shadow
     */

  --shadow-1: 0 6px 24px var(--black_5);
  --shadow-2: 0 2px 28px var(--black_10);

  /**
     * border radius
     */

  --radius-2: 2px;
  --radius-5: 5px;
  --radius-8: 8px;

  /**
     * transition
     */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

  --width: 510px;
  --height: 700px;
}

/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
span,
time,
label,
input,
button,
textarea,
ion-icon {
  display: block;
}

img {
  height: auto;
}

input,
button,
textarea {
  background: none;
  border: none;
  font: inherit;
}

input,
textarea {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

html {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-roboto);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white-1);
  color: hsl(220, 5%, 49%);
  color: var(--gray-web);
  font-size: 1.6rem;
  line-height: 1.8;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}

:focus-visible {
  outline-offset: 4px;
}

/*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/

.container {
  padding-inline: 15px;
}

.btn {
  max-width: -webkit-max-content;
  max-width: max-content;
  color: hsl(0, 0%, 100%);
  color: var(--white-1);
  font-size: 1.3rem;
  font-size: var(--fs-6);
  font-weight: 700;
  font-weight: var(--fw-700);
  padding: 10px 30px;
  border-radius: 5px;
  border-radius: var(--radius-5);
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.btn-primary {
  background-image: linear-gradient(
    to left top,
    hsl(328, 100%, 59%),
    hsl(25, 100%, 50%));
  background-image: var(--gradient);
  background-size: 1000%;
}

.btn-primary:is(:hover, :focus) {
  background-position: bottom right;
}

.btn-secondary {
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white-1);
  color: hsl(240, 6%, 10%);
  color: var(--eerie-black);
}

.btn-secondary:is(:hover, :focus) {
  background-color: hsl(240, 6%, 10%);
  background-color: var(--eerie-black);
  color: hsl(0, 0%, 100%);
  color: var(--white-1);
}

.section {
  padding-block: 60px;
  padding-block: var(--section-padding);
}

.h1,
.h2,
.h3 {
  color: hsl(240, 6%, 10%);
  color: var(--eerie-black);
  font-family: "League Spartan", sans-serif;
  font-family: var(--ff-league-spartan);
  line-height: 1.2;
}

.h1 {
  font-size: 3.5rem;
  font-size: var(--fs-1);
}

.h2 {
  font-size: 3rem;
  font-size: var(--fs-2);
}

.h3 {
  font-size: 2.1rem;
  font-size: var(--fs-3);
}

.w-100 {
  width: 100%;
}

.section-title,
.section-text {
  text-align: center;
  padding-bottom: 20px;
}

.section-text {
  font-size: 1.3rem;
  font-size: var(--fs-6);
}

.grid-list {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
}

.img-holder {
  aspect-ratio: 510px / 700px;
  aspect-ratio: var(--width) / var(--height);
  background-color: transparent;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*-----------------------------------*\
    #HEADER
  \*-----------------------------------*/

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white-1);
  padding-block: 15px;
  -webkit-border-after: 1px solid hsl(0, 0%, 93%);
          border-block-end: 1px solid hsl(0, 0%, 93%);
  -webkit-border-after: 1px solid var(--cultured);
          border-block-end: 1px solid var(--cultured);
  z-index: 4;
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.header.active {
  -webkit-filter: drop-shadow(0 2px 28px hsla(0, 0%, 0%, 0.1));
          filter: drop-shadow(0 2px 28px hsla(0, 0%, 0%, 0.1));
  -webkit-filter: drop-shadow(var(--shadow-2));
          filter: drop-shadow(var(--shadow-2));
}

.header > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-family: "League Spartan", sans-serif;
  font-family: var(--ff-league-spartan);
  color: hsl(0, 0%, 0%);
  color: var(--black);
  font-size: 3.5rem;
  font-weight: 700;
  font-weight: var(--fw-700);
  line-height: 1;
}

.nav-toggle-btn {
  font-size: 40px;
}

.nav-toggle-btn.active .open,
.nav-toggle-btn .close {
  display: none;
}

.nav-toggle-btn .open,
.nav-toggle-btn.active .close {
  display: block;
}

.navbar {
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white-1);
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: calc(100% - 30px);
  width: 100%;
  padding-inline: 0;
  border: 1px solid hsl(0, 0%, 93%);
  border: 1px solid var(--cultured);
  transition: 0.3s cubic-bezier(0.33, 0.85, 0.4, 0.96);
  transition: 0.3s var(--cubic-out);
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
}

.navbar.active {
  max-height: 320px;
  visibility: visible;
  transition-duration: 0.5s;
}

.navbar-list {
  padding: 20px 15px;
  -webkit-padding-before: 10px;
          padding-block-start: 10px;
  opacity: 0;
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.navbar.active .navbar-list {
  opacity: 1;
}

.navbar-link {
  color: hsl(240, 6%, 10%);
  color: var(--eerie-black);
  font-family: "League Spartan", sans-serif;
  font-family: var(--ff-league-spartan);
  font-size: 1.7rem;
  font-size: var(--fs-4);
  line-height: 1.2;
  padding-block: 8px;
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.navbar-link:is(:hover, :focus) {
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
}

.header .btn {
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
}

/*-----------------------------------*\
    #HEO
  \*-----------------------------------*/

.hero {
  -webkit-padding-before: calc(60px + 50px);
          padding-block-start: calc(60px + 50px);
  -webkit-padding-before: calc(var(--section-padding) + 50px);
          padding-block-start: calc(var(--section-padding) + 50px);
  text-align: center;
}

.hero-content {
  -webkit-margin-after: 30px;
          margin-block-end: 30px;
}

.hero-subtitle {
  color: hsl(240, 6%, 10%);
  color: var(--eerie-black);
  font-size: 1.4rem;
  font-size: var(--fs-5);
  font-weight: 500;
  font-weight: var(--fw-500);
}

.hero-title {
  margin-block: 12px 8px;
}

.hero-text {
  font-size: 1.4rem;
  font-size: var(--fs-5);
}

.hero .btn {
  margin-inline: auto;
  -webkit-margin-before: 20px;
          margin-block-start: 20px;
}

/*-----------------------------------*\
    #SERVICE
  \*-----------------------------------*/

.service {
  background-color: hsl(0, 14%, 98%);
  background-color: var(--white-2);
}

.service .section-text {
  margin-block: 5px 35px;
}

.service-card {
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white-1);
  padding: 20px 15px;
  border: 1px solid hsl(0, 0%, 90%);
  border: 1px solid var(--platinum);
  border-radius: 5px;
  border-radius: var(--radius-5);
  text-align: center;
  box-shadow: 0 6px 24px hsla(0, 0%, 0%, 0.05);
  box-shadow: var(--shadow-1);
  transition: 0.5s ease;
  transition: var(--transition-2);
}

.service-card:is(:hover, :focus-within) {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.service-card .card-icon {
  color: hsl(0, 0%, 100%);
  color: var(--white-1);
  font-size: 25px;
  max-width: -webkit-max-content;
  max-width: max-content;
  margin-inline: auto;
  padding: 18px;
  border-radius: 50%;
}

.service-card .card-title {
  margin-block: 20px 8px;
}

.service-card .card-text {
  font-size: 1.3rem;
  font-size: var(--fs-6);
}

/*-----------------------------------*\
    #PROJECT
  \*-----------------------------------*/

.project .section-text {
  margin-block: 5px 35px;
}

.project-card {
  align-items: center;
  position: relative;
  border-radius: 8px;
  border-radius: var(--radius-8);
  overflow: hidden;
}

.project-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsl(0, 0%, 0%);
  background-color: var(--black);
  opacity: 0.5;
  transition: 0.5s ease;
  transition: var(--transition-2);
}

.project-card:is(:hover, :focus-within)::after {
  opacity: 0.8;
}

.project-card .card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  z-index: 1;
  transition: 0.5s ease;
  transition: var(--transition-2);
  text-align: left;
}

.project-card:is(:hover, :focus-within) .card-content {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.project-card .card-subtitle {
  color: hsl(230, 19%, 81%);
  color: var(--lavender-gray);
  font-size: 1.3rem;
  font-size: var(--fs-6);
  line-height: 1;
}

.project-card .card-title {
  color: hsl(0, 0%, 100%);
  color: var(--white-1);
  margin-block: 12px 15px;
}

/*-----------------------------------*\
    #ABOUT
  \*-----------------------------------*/

.about {
  background-color: hsl(0, 14%, 98%);
  background-color: var(--white-2);
}

.about-banner {
  position: relative;
  border-radius: 5px;
  border-radius: var(--radius-5);
  overflow: hidden;

  -webkit-margin-after: 25px;

          margin-block-end: 25px;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white-1);
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
  font-size: 32px;
  padding: 16px;
  border-radius: 50%;
  -webkit-animation: pulse 1.5s ease-out infinite;
          animation: pulse 1.5s ease-out infinite;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 1px hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px var(--white-1);
  }
  100% {
    box-shadow: 0 0 0 25px transparent;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 1px hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px var(--white-1);
  }
  100% {
    box-shadow: 0 0 0 25px transparent;
  }
}

.about :is(.section-title, .section-text) {
  text-align: left;
}

.about .section-title {
  -webkit-margin-after: 5px;
          margin-block-end: 5px;
}

.about .section-text:not(:last-child) {
  -webkit-margin-after: 18px;
          margin-block-end: 18px;
}

.about .h3 {
  -webkit-margin-after: 8px;
          margin-block-end: 8px;
}

.about-list {
  margin-block: 15px 18px;
}

.about-item {
  display: flex;
  align-items: flex-start;
  grid-gap: 5px;
  gap: 5px;
}

.about-item ion-icon {
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
  flex-shrink: 0;
  -webkit-margin-before: 2px;
          margin-block-start: 2px;
}

.about-item:not(:last-child) {
  -webkit-margin-after: 12px;
          margin-block-end: 12px;
}

/*-----------------------------------*\
    #CTA
  \*-----------------------------------*/

.cta {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: 1;
}

.cta::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to left top,
    hsl(328, 100%, 59%),
    hsl(25, 100%, 50%));
  background-image: var(--gradient);
  opacity: 0.95;
  z-index: -1;
}

.cta-subtitle,
.cta .section-title {
  color: hsl(0, 0%, 100%);
  color: var(--white-1);
}

.cta-subtitle {
  font-size: 1.3rem;
  font-size: var(--fs-6);
  font-weight: 500;
  font-weight: var(--fw-500);
  text-align: center;
}

.cta .section-title {
  margin-block: 12px 18px;
}

.cta .btn {
  margin-inline: auto;
}

/*-----------------------------------*\
    #BLOG
  \*-----------------------------------*/

.blog .section-text {
  margin-block: 5px 35px;
}

.blog-card {
  background-color: hsl(0, 14%, 98%);
  background-color: var(--white-2);
}

.blog-card .card-banner {
  overflow: hidden;
}

.blog-card .card-banner img {
  transition: 0.5s ease;
  transition: var(--transition-2);
}

.blog-card:is(:hover, :focus-within) .card-banner img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.blog-card .card-content {
  padding: 15px;
}

.blog-card .time {
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
  font-size: 1.3rem;
  font-size: var(--fs-6);
  line-height: 1;
  -webkit-margin-after: 10px;
          margin-block-end: 10px;
}

.blog-card .card-title {
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.blog-card .card-title:is(:hover, :focus) {
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
}

/*-----------------------------------*\
    #CONTACT
  \*-----------------------------------*/

.contact {
  background-color: hsl(0, 14%, 98%);
  background-color: var(--white-2);
}

.contact .section-text {
  margin-block: 5px 35px;
}

.contact-form {
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white-1);
  padding: 20px;
  border-radius: 2px;
  border-radius: var(--radius-2);
  -webkit-margin-after: 30px;
          margin-block-end: 30px;
  box-shadow: 0 6px 24px hsla(0, 0%, 0%, 0.05);
  box-shadow: var(--shadow-1);
}

.input-field {
  background-color: hsl(0, 14%, 98%);
  background-color: var(--white-2);
  color: hsl(240, 6%, 10%);
  color: var(--eerie-black);
  font-size: 1.4rem;
  font-size: var(--fs-5);
  padding: 15px;
  border-radius: 2px;
  border-radius: var(--radius-2);
  outline: 1px solid transparent;
  outline-offset: 0;
  -webkit-margin-after: 15px;
          margin-block-end: 15px;
}

.input-field::-webkit-inner-spin-button {
  display: none;
}

.input-field:focus {
  outline-color: hsl(341, 100%, 49%);
  outline-color: var(--red-crayola);
}

.input-field::-webkit-input-placeholder {
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  -webkit-transition: var(--transition-1);
  transition: var(--transition-1);
}

.input-field::placeholder {
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.input-field:focus::-webkit-input-placeholder {
  opacity: 0;
}

.input-field:focus::placeholder {
  opacity: 0;
}

textarea.input-field {
  resize: vertical;
  min-height: 80px;
  height: 100px;
  max-height: 200px;
  overscroll-behavior: contain;
}

.checkbox {
  width: -webkit-max-content;
  width: max-content;
  -webkit-margin-before: 5px;
          margin-block-start: 5px;
  accent-color: hsl(341, 100%, 49%);
  accent-color: var(--red-crayola);
}

.label-link {
  display: inline-block;
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
}

.label-link:is(:hover, :focus) {
  text-decoration: underline;
}

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  -webkit-margin-after: 15px;
          margin-block-end: 15px;
}

.checkbox-wrapper .label {
  font-size: 1.3rem;
  font-size: var(--fs-6);
}

.contact-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  grid-gap: 30px 25px;
  gap: 30px 25px;
}

.contact-item {
  min-width: 100%;
  align-items: center;
}

.contact-card {
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white-1);
  padding: 20px;
  border-radius: 2px;
  border-radius: var(--radius-2);
  box-shadow: 0 6px 24px hsla(0, 0%, 0%, 0.05);
  box-shadow: var(--shadow-1);
  /* display: flex; */
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.contact-card .card-icon {
  background-color: hsl(210, 60%, 98%);
  background-color: var(--cultured-2);
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
  font-size: 25px;
  padding: 13px;
  border-radius: 50%;
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.contact-card:is(:hover, :focus) .card-icon {
  background-color: hsl(341, 100%, 49%);
  background-color: var(--red-crayola);
  color: hsl(0, 0%, 100%);
  color: var(--white-1);
}

.contact-card .card-title {
  -webkit-margin-after: 5px;
          margin-block-end: 5px;
}

.contact-card :is(.card-link, .card-address) {
  font-size: 1.3rem;
  font-size: var(--fs-6);
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.contact-card .card-link:is(:hover, :focus) {
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
}

/*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/

.footer {
  background-color: hsl(0, 14%, 98%);
  background-color: var(--white-2);
  padding-block: 20px;
}

.copyright {
  font-size: 1.3rem;
  font-size: var(--fs-6);
  text-align: center;
}

.copyright-link {
  color: hsl(341, 100%, 49%);
  color: var(--red-crayola);
  font-weight: 500;
  font-weight: var(--fw-500);
  display: inline-block;
}

/*-----------------------------------*\
    #BACK TO TOP
  \*-----------------------------------*/

.back-top-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: hsl(240, 6%, 10%);
  background-color: var(--eerie-black);
  color: hsl(0, 0%, 100%);
  color: var(--white-1);
  padding: 12px;
  border-radius: 50%;
  box-shadow: 0 2px 28px hsla(0, 0%, 0%, 0.1);
  box-shadow: var(--shadow-2);
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s ease;
  transition: var(--transition-1);
}

.back-top-btn.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.back-top-btn:is(:hover, :focus) {
  opacity: 0.9;
}

/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

/**
   * responsive for larger than 575px screen
   */

@media (min-width: 575px) {
  /**
     * REUSED STYLE
     */

  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }

  .grid-list {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    -webkit-column-gap: 25px;
            column-gap: 25px;
  }

  /**
     * BLOG
     */

  .blog .grid-list {
    grid-template-columns: 1fr;
  }

  .blog-card.grid {
    display: grid;
    grid-template-columns: 0.47fr 1fr;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }

  /**
     * CONTACT
     */

  .input-wrapper {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
  }

  .contact-item {
    min-width: calc(50% - 18px);
    align-items: center;
  }
}

/**
   * responsive for larger than 768px screen
   */

@media (min-width: 768px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
       * typography
       */

    --fs-1: 4.5rem;
    --fs-2: 3.7rem;
    --fs-3: 2.3rem;
    --fs-4: 1.8rem;
    --fs-5: 1.5rem;
    --fs-6: 1.4rem;
  }

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 720px;
  }

  .section-text {
    max-width: 65ch;
    margin-inline: auto;
  }

  /**
     * HERO
     */

  .hero-text {
    max-width: 60ch;
    margin-inline: auto;
  }

  /**
     * ABOUT
     */

  .about-banner {
    max-width: 550px;
  }

  .about .section-text {
    max-width: unset;
    margin-inline: 0;
  }

  /**
     * CONTACT
     */

  .contact-form {
    padding: 30px;
  }

  .contact-item {
    min-width: calc(50% - 12.5px);
    align-items: center;
  }
}

/**
   * responsive for larger than 992px screen
   */

@media (min-width: 992px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
       * typography
       */

    --fs-1: 5rem;
    --fs-2: 4rem;
    --fs-3: 2.5rem;

    /**
       * spacing
       */

    --section-padding: 100px;
  }

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 960px;
  }

  .grid-list {
    grid-template-columns: repeat(3, 1fr);
  }

  /**
     * HEADER
     */

  .nav-toggle-btn {
    display: none;
  }

  .navbar,
  .navbar.active {
    all: unset;
  }

  .navbar-list {
    all: unset;
    display: flex;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
  }

  .navbar-link {
    position: relative;
  }

  .navbar-link:is(:hover, :focus) {
    color: hsl(240, 6%, 10%);
    color: var(--eerie-black);
  }

  .navbar-link::after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(
    to left top,
    hsl(328, 100%, 59%),
    hsl(25, 100%, 50%));
    background-image: var(--gradient);
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    transition: 0.5s ease;
    transition: var(--transition-2);
  }

  .navbar-link:is(:hover, :focus)::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }

  .header .btn {
    -webkit-margin-before: 0;
            margin-block-start: 0;
  }

  /**
     * HERO
     */

  .hero .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    text-align: left;
  }

  .hero-content {
    -webkit-margin-after: 0;
            margin-block-end: 0;
  }

  .hero-subtitle {
    position: relative;
    -webkit-padding-start: 15px;
            padding-inline-start: 15px;
  }

  .hero-subtitle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: hsl(341, 100%, 49%);
    background-color: var(--red-crayola);
  }

  .hero-text,
  .hero .btn {
    margin-inline: 0;
  }

  /**
     * SERVICE
     */

  .service-card {
    padding: 20px;
  }

  .service-card .h3 {
    --fs-3: 2.3rem;
  }

  /**
     * PROJECT
     */

  .project-card .card-subtitle {
    --fs-6: 1.5rem;
  }

  /**
     * ABOUT
     */

  .about .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
  }

  .about-banner {
    -webkit-margin-after: 0;
            margin-block-end: 0;
  }

  /**
     * BLOG
     */

  .blog .grid-list {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .blog .grid-list > li:first-child {
    grid-column: 1 / 2;
    grid-row: 1 / 5;
  }

  .blog-card:not(.grid) {
    height: 100%;
  }

  .blog-card .card-content {
    padding-block: 10px;
  }

  .blog-card:not(.grid) .card-content {
    padding: 25px;
  }

  .blog-card.grid .h3 {
    --fs-3: 2rem;
  }

  .blog-card .time {
    --fs-6: 1.6rem;
  }

  /**
     * CONTACT
     */

  .checkbox-wrapper {
    margin-block: 20px;
  }

  .contact-item {
    min-width: calc(33.33% - 16.66px);
    align-items: center;
  }

  .contact-card {
    padding: 30px;
  }

  .contact-card .card-icon {
    font-size: 32px;
  }
}

/**
   * responsive for larger than 1200px screen
   */

@media (min-width: 1200px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
       * typography
       */

    --fs-1: 6.8rem;
    --fs-2: 4.5rem;
    --fs-4: 1.9rem;
    --fs-5: 1.6rem;
    --fs-6: 1.6rem;
  }

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 1140px;
  }

  .btn {
    --fs-6: 1.5rem;
  }

  /**
     * SERVICE
     */

  .service-card {
    padding: 30px;
  }

  .service-card .h3 {
    --fs-3: 2.5rem;
  }

  /**
     * PROJECT
     */

  .project-card .card-content {
    padding: 30px;
    text-align: center;
  }

  .project-card .card-subtitle {
    --fs-6: 1.6rem;
  }

  /**
     * ABOUT
     */

  .about .container {
    grid-gap: 60px;
    gap: 60px;
  }

  .about-item {
    grid-gap: 7px;
    gap: 7px;
  }

  .about-item ion-icon {
    font-size: 20px;
  }

  /**
     * CTA
     */

  .cta .section-title {
    margin-block: 15px 24px;
  }

  /**
     * BLOG
     */

  .blog-card .time {
    --fs-6: 1.6rem;
  }

  .blog-card.grid .h3 {
    --fs-3: 2.4rem;
  }

  /**
     * CONTACT
     */

  .contact-form {
    padding: 50px;
  }

  .contact-card {
    grid-gap: 20px;
    gap: 20px;
  }

  .contact-card .card-icon {
    padding: 15px;
  }
}

/* .team-boxed {
  color: #313437;
  background-color: #eef4f7;

} */

/*.team-boxed p {
  color: #7d8285;
}

.team-boxed h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width: 767px) {
  .team-boxed h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.team-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.team-boxed .intro p {
  margin-bottom: 0;
}

.team-boxed .people {
  padding: 50px 0;
}

.team-boxed .item {
  text-align: center;
}

.team-boxed .item .box {
  text-align: center;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
}

.team-boxed .item .name {
  font-weight: bold;
  margin-top: 28px;
  margin-bottom: 8px;
  color: inherit;
}

.team-boxed .item .title {
  text-transform: uppercase;
  font-weight: bold;
  color: #d0d0d0;
  letter-spacing: 2px;
  font-size: 13px;
}

.team-boxed .item .description {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.team-boxed .item img {
  max-width: 160px;
}

.team-boxed .social {
  font-size: 18px;
  color: #a2a8ae;
}

.team-boxed .social a {
  color: inherit;
  margin: 0 10px;
  display: inline-block;
  opacity: 0.7;
}

.team-boxed .social a:hover {
  opacity: 1;
}
.card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  width: 200px;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .card {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 100%;
  }
}
*/
.font-link {
  font-family: "League", cursive;
}

.team-boxed {
  color: #313437;
  background-color: #eef4f7;
}
.team-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.team-boxed > .intro > p {
  margin-bottom: 10px;
  font-size: 200px;
}
#contact > div > p {
  font-size: 17px;
  /* font-weight: bold; */
  color: black;
}
.team-boxed .intro h2 {
  margin-bottom: 0;
}
.team-boxed .social a:hover {
  opacity: 1;
}
.team-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.team-card {
  width: 350px;
  /* margin: 10px; */
  text-align: center;
  background-color: var(--white-1);
  padding: 20px;
  border-radius: var(--radius-2);
  -webkit-margin-after: 30px;
          margin-block-end: 30px;
  box-shadow: var(--shadow-1);
}

.team-card-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.team-card-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-card-name {
  padding-top: 20px;
  font-size: 17px;
  color: black;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .team-card {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .team-card {
    width: 100%;
  }
}

/*--------------------------------------------------------------------------------------
PRELOADER
--------------------------------------------------------------------------------------*/
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: blanchedalmond;
  z-index: 9999999;
  text-align: center;
}
.PreloaderImage {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 40px;
  display: flex;
  align-items: center;
}
.line {
  width: 5px;
  height: 40px;
  background: black;
  margin: 0 3px;
  border-radius: 10px;
  -webkit-animation: loading 0.8s infinite;
          animation: loading 0.8s infinite;
}
.line:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.line:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.line:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.line:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.line:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.line:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.line:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
@-webkit-keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 40px;
  }
  100% {
    height: 0;
  }
}
@keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 40px;
  }
  100% {
    height: 0;
  }
}

