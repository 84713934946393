/* .team-boxed {
  color: #313437;
  background-color: #eef4f7;

} */

/*.team-boxed p {
  color: #7d8285;
}

.team-boxed h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width: 767px) {
  .team-boxed h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.team-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.team-boxed .intro p {
  margin-bottom: 0;
}

.team-boxed .people {
  padding: 50px 0;
}

.team-boxed .item {
  text-align: center;
}

.team-boxed .item .box {
  text-align: center;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
}

.team-boxed .item .name {
  font-weight: bold;
  margin-top: 28px;
  margin-bottom: 8px;
  color: inherit;
}

.team-boxed .item .title {
  text-transform: uppercase;
  font-weight: bold;
  color: #d0d0d0;
  letter-spacing: 2px;
  font-size: 13px;
}

.team-boxed .item .description {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.team-boxed .item img {
  max-width: 160px;
}

.team-boxed .social {
  font-size: 18px;
  color: #a2a8ae;
}

.team-boxed .social a {
  color: inherit;
  margin: 0 10px;
  display: inline-block;
  opacity: 0.7;
}

.team-boxed .social a:hover {
  opacity: 1;
}
.card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  width: 200px;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .card {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 100%;
  }
}
*/
.font-link {
  font-family: "League", cursive;
}

.team-boxed {
  color: #313437;
  background-color: #eef4f7;
}
.team-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.team-boxed > .intro > p {
  margin-bottom: 10px;
  font-size: 200px;
}
#contact > div > p {
  font-size: 17px;
  /* font-weight: bold; */
  color: black;
}
.team-boxed .intro h2 {
  margin-bottom: 0;
}
.team-boxed .social a:hover {
  opacity: 1;
}
.team-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.team-card {
  width: 350px;
  /* margin: 10px; */
  text-align: center;
  background-color: var(--white-1);
  padding: 20px;
  border-radius: var(--radius-2);
  margin-block-end: 30px;
  box-shadow: var(--shadow-1);
}

.team-card-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.team-card-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-card-name {
  padding-top: 20px;
  font-size: 17px;
  color: black;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .team-card {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .team-card {
    width: 100%;
  }
}
